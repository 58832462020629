import React from "react"
import { Box, Flex, Heading, Center } from "@chakra-ui/core"
import Sidebar from "../Sidebar"
import MemoryQuestLogo from "../MemoryQuestLogo"
import Factsheet from "../Factsheet"

interface Props {}

const PressKit: React.FC<Props> = ({ children }) => {
  return (
    <Box
      fontSize="sm"
      color="gray.600"
      margin="0 auto"
      paddingX={5}
      maxWidth={1200}
    >
      <Flex direction="row">
        <Box style={{ position: "fixed" }} w={250} paddingTop={5}>
          <Heading marginLeft={5} marginBottom={5} as="h1" size="md">
            Mad Mustache
          </Heading>
          <Sidebar />
        </Box>
        <Box marginLeft={250} direction="column" w="100%">
          <Center
            paddingY={5}
            bg="gray.700"
            borderBottomRadius={30}
            marginBottom={10}
          >
            <MemoryQuestLogo />
          </Center>

          <main>
            <Flex>
              <Factsheet />
              <Box marginLeft={10}>
                {children}
                <Box marginBottom={5}>
                  <footer>
                    {new Date().getFullYear()} Madmustache Company
                  </footer>
                </Box>
              </Box>
            </Flex>
          </main>
        </Box>
      </Flex>
    </Box>
  )
}

export default PressKit
