import React from "react"
import { Box, Text, Heading } from "@chakra-ui/core"

interface Props {
  title: string
}

const PressKitBodyBlock: React.FC<Props> = ({ title, children }) => {
  return (
    <Box
      marginBottom={5}
      paddingBottom={5}
      borderBottomColor="gray.200"
      borderBottomWidth={1}
    >
      <Heading as="h2" size="md" marginBottom="5">
        {title}
      </Heading>
      <Box>{children}</Box>
    </Box>
  )
}

export default PressKitBodyBlock
