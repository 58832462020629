import React from "react"
import { Box, Text, Button } from "@chakra-ui/core"
import { Link } from "gatsby"
import { FiVideo, FiFileText, FiArchive, FiImage, FiMail } from "react-icons/fi"
interface Props {}

const Sidebar: React.FC<Props> = () => {
  return (
    <Box>
      <nav>
        <SidebarLink icon={<FiVideo />} label="Factsheet" to="#factsheet" />
        <SidebarLink
          icon={<FiFileText />}
          label="Description"
          to="#description"
        />
        <SidebarLink icon={<FiArchive />} label="History" to="#history" />
        <SidebarLink icon={<FiVideo />} label="Videos" to="#videos" />
        <SidebarLink icon={<FiImage />} label="Images" to="#images" />
        <SidebarLink
          icon={<FiVideo />}
          label="Logo & Icons"
          to="#logo-and-icons"
        />
        <SidebarLink icon={<FiMail />} label="Contact" to="#contact" />
      </nav>
    </Box>
  )
}

const SidebarLink = ({ icon, label, to }) => {
  return (
    <Box
      paddingY={2}
      paddingX={5}
      marginRight={3}
      borderRightRadius={20}
      transition="all 0.5s"
      borderLeftColor="transparent"
      _hover={{
        backgroundColor: "gray.100",
        // borderLeftColor: "grey.400",
      }}
    >
      <Link style={{ display: "block" }} to={to}>
        <Button
          leftIcon={icon}
          variant="link"
          _hover={{ textDecoration: "none" }}
        >
          {label}
        </Button>
      </Link>
    </Box>
  )
}

export default Sidebar
