import React from "react"
import { Box, Heading, Text } from "@chakra-ui/core"

interface Props {}

const Factsheet: React.FC<Props> = () => {
  return (
    <Box w={250}>
      <Heading as="h2" size="md" marginBottom={5}>
        Factsheet
      </Heading>
      <Block title="Developer:">
        <Text>MadMustacheCompany</Text>
        <Text>Hamilton New Zealand</Text>
      </Block>
      <Block title="Release date:">
        <Text>July 20th, 2020 (android), August 22, 2020 (ios)</Text>
      </Block>
      <Block title="Platforms:">
        <Text>Google Play</Text>
        <Text>iOS</Text>
      </Block>
      <Block title="Website:">
        <Text>https://memo-quest.com</Text>
      </Block>
      <Block title="Regular Price:">
        <Text>USD 14.99</Text>
        <Text>EUR 14.99</Text>
        <Text>NZD 14.99</Text>
      </Block>
    </Box>
  )
}

const Block = ({ title, children }) => {
  return (
    <Box marginBottom={5}>
      <Heading as="h3" size="sm" marginBottom={3}>
        {title}
      </Heading>
      <Box>
        <Text>{children}</Text>
      </Box>
    </Box>
  )
}
export default Factsheet
