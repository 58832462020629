import React from "react"
import PressKit from "../../components/layouts/PressKit"
import PressKitBodyBlock from "../../components/PressKitBodyBlock"
import { Text, UnorderedList, ListItem } from "@chakra-ui/core"
import Video from "../../components/Video"

interface Props {}

const MemoQuest: React.FC<Props> = () => {
  return (
    <PressKit>
      <PressKitBodyBlock title="Description">
        <Text>
          Octodad: Dadliest Catch is a game about destruction, deception, and
          fatherhood. The player controls Octodad, a dapper octopus masquerading
          as a human, as he goes about his life. Octodad's existence is a
          constant struggle, as he must master mundane tasks with his unwieldy
          boneless tentacles while simultaneously keeping his cephalopodan
          nature a secret from his human family.
        </Text>
      </PressKitBodyBlock>
      <PressKitBodyBlock title="History">
        <Text>
          In Octo-ber 2010, a group of university students released the original
          Octodad into the world. Despite everyone being convinced that the game
          was about a normal human dad, the game was a cult hit, gained critical
          acclaim, and was an Independent Games Festival Student Finalist in
          2011. Octodad received enough attention that the team decided to run a
          Kickstarter, form the game studio Young Horses, and create a bigger,
          better, more polished game about Octodad. Two and a half years after
          beginning production, Octodad: Dadliest Catch is ready to flop
          awkwardly into your arms.
        </Text>
      </PressKitBodyBlock>
      <PressKitBodyBlock title="Features">
        <UnorderedList>
          <ListItem>
            Octodad’s wacky physics create hilarious moments that are different
            every time. Be surprised by the randomness of Octodad’s flailing or
            express your own sense of humor by making Octodad do silly things.
          </ListItem>
          <ListItem>
            Octodad’s wacky physics create hilarious moments that are different
            every time. Be surprised by the randomness of Octodad’s flailing or
            express your own sense of humor by making Octodad do silly things.
          </ListItem>
          <ListItem>
            Octodad’s wacky physics create hilarious moments that are different
            every time. Be surprised by the randomness of Octodad’s flailing or
            express your own sense of humor by making Octodad do silly things.
          </ListItem>
          <ListItem>
            Octodad’s wacky physics create hilarious moments that are different
            every time. Be surprised by the randomness of Octodad’s flailing or
            express your own sense of humor by making Octodad do silly things.
          </ListItem>
        </UnorderedList>
      </PressKitBodyBlock>
      <PressKitBodyBlock title="Videos">
        <Video
          videoTitle="Game Trailer"
          videoSrcURL="https://www.youtube.com/embed/A02LMB1lA1o"
        />
      </PressKitBodyBlock>
      <PressKitBodyBlock title="Images"></PressKitBodyBlock>
    </PressKit>
  )
}

export default MemoQuest
